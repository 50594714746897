import { SearchFilter } from "src/components/Filter/SearchFilter"
import { ParadiseAppliedFilters } from "src/components/Paradise/ParadiseAppliedFilters"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import {
  TParadiseProfilesFilters,
  useParadiseProfilesFilter,
} from "src/components/Paradise/ParadiseProfiles/useParadiseProfiles"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  FilterWrapper,
  ParadiseLayout,
  ParadiseTitle,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { useFetchParadiseProfiles } from "src/data/paradise/paradiseProfiles/queries/paradiseProfileQueries"
import { IParadiseProfile } from "src/data/paradise/paradiseProfiles/types/paradiseProfileQueryTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { ColumnPopoverWrapper } from "src/ui/GridTable/useTableColumns/tableColumnStyles"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import FilterIcon from "src/ui/icons/calibrating.svg"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatUtcDate } from "src/utils/l10n"

const profileFilterLabel: Record<keyof TParadiseProfilesFilters, string> = {
  profiles_offset: "Offset",
  profiles_id: "Profile ID",
  profiles_name: "Profile name",
  profiles_organization_id: "Organization ID",
} as const

const LIMIT = 20

export function ParadiseProfiles() {
  const { navigate } = useRouter()

  const { filter, sortedFilter, setFilter, offset, setOffset } =
    useParadiseProfilesFilter()

  const tableColumns: TableColumn<IParadiseProfile>[] = [
    {
      value: "profileId",
      label: "Profile ID",
      disabled: true,
      columnWidth: "auto",
      disableClickPropagation: true,
      renderCell: (data) => (
        <InternalLink to={Routes.ParadiseProfile.location(data.id)}>
          {data.id}
        </InternalLink>
      ),
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.profiles_id ?? undefined}
            placeholder="Search by id"
            onChange={(v) => setFilter("profiles_id", v)}
          />
          <MText variant="bodyS">Make sure to type in the exact ID</MText>
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      value: "name",
      label: "Name",
      disabled: true,
      columnWidth: "auto",
      renderCell: (data) => <div>{data.name}</div>,
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.profiles_name ?? undefined}
            placeholder="Search by name"
            onChange={(v) => setFilter("profiles_name", v)}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      value: "organizationId",
      label: "Organization ID",
      columnWidth: "auto",
      disableClickPropagation: true,
      renderCell: (data) => (
        <InternalLink
          to={Routes.ParadiseOrganization.location(data.organization_id)}
        >
          {data.organization_id}
        </InternalLink>
      ),
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.profiles_organization_id ?? undefined}
            placeholder="Search by organization id"
            onChange={(v) => setFilter("profiles_organization_id", v)}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      value: "createdAt",
      label: "Created at",
      columnWidth: "auto",
      renderCell: (data) => (
        <div>{formatUtcDate({ date: data.created_at })}</div>
      ),
    },
    {
      value: "lastUpdatedAt",
      label: "Last updated at",
      columnWidth: "auto",
      renderCell: (data) => (
        <div>{formatUtcDate({ date: data.last_modified })}</div>
      ),
    },
  ]

  const fetchProfiles = useFetchParadiseProfiles({
    filter: {
      offset,
      limit: LIMIT,
      name: filter.profiles_name || undefined,
      id: filter.profiles_id || undefined,
      organization_id: filter.profiles_organization_id || undefined,
    },
    options: {
      keepPreviousData: true,
    },
  })

  const {
    headerElements,
    rows,
    templateColumns,
    interactiveColumns,
    interactiveVisibleColumns,
    updateColumnVisibility,
  } = useTableColumns({
    columns: tableColumns,
    data: fetchProfiles.data?.profiles,
    options: { localStorageKey: "minut.profiles.homes.table" },
  })

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            Profiles
            {fetchProfiles.isSuccess && (
              <MBadge color="info">
                Total: {fetchProfiles.data?.paging.total_count}
              </MBadge>
            )}
          </ParadiseTitle>
        }
      />
      <TopWrapper>
        <FilterWrapper>
          <ParadiseAppliedFilters
            filters={sortedFilter.map((f) => ({
              label: profileFilterLabel[f.key],
              value: String(f.value),
              onRemove: () => setFilter(f.key, null),
            }))}
          />
        </FilterWrapper>
        <DropdownMultiSelect
          label="Columns"
          options={interactiveColumns}
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          onChange={({ checked, option }) =>
            updateColumnVisibility(option.value, !checked)
          }
        />
      </TopWrapper>
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        onRowClick={(index) => {
          if (fetchProfiles.data?.profiles) {
            navigate(
              Routes.ParadiseProfile.location(
                // @ts-expect-error: noUncheckedIndexedAccess
                fetchProfiles.data.profiles[index].id
              )
            )
          }
        }}
      />
      <ParadisePager
        offset={offset}
        limit={LIMIT}
        setOffset={setOffset}
        totalCount={fetchProfiles.data?.paging.total_count}
      />
    </ParadiseLayout>
  )
}
